import { useState, useEffect, } from 'react';
import { getCompanyInfo } from './services/services';
import * as language from '../src/constants/languages'
import StoreChoose from './pages/StoreChoose';
import { paymentResponseRouter, router } from './config/router'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SpinnerLoader from './components/SpinnerLoader';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { RouterProvider } from 'react-router-dom';
import PaymentFailPage from './pages/PaymentFailPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';


function App() {

  // check if store choosed
  async function checkCompanyInfo() {
    const value = localStorage.getItem('companyInfo')
    if (!value) {
      await getCompanyInfo(language.companyidValue).then((response) => {
        if (response.status === 'success') {
          localStorage.setItem('companyInfo', JSON.stringify(response.data));
        }
      })
        .catch((error) => {
          // console.log(error, 'company info error');
        })
    }
  }

  useEffect(() => {
    checkCompanyInfo();
    
  }, [])


  return (


    <div >
      {
        // storeChoosed != null ?
          <div >
            <div className='routerContainer'>
              <RouterProvider router={router} />
            </div>
          </div>
          // :
          // <div className='SpinnerContainer'>
          //   <StoreChoose />
          //   <SpinnerLoader />
          // </div>
      }
    </div>
  );
}

export default App;
