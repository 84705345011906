import QRCode from "react-qr-code";

import html2canvas from "html2canvas";

import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg'
import VerifiedIcon from '../assets/svg/verified.svg'
import DownloadIcon from "../assets/svg/download.svg"

export default function OrderPlacedComponent({ currencySymbol, navigate }) {
    const orderPlacedData = {
        "orderId": 4508,
        "orderNo": 22,
        "orderNoPrefix": "",
        "orderNoSuffix": "",
        "orderDate": "2023-12-05T15:32:40.9502975+05:30",
        "customerId": 1582,
        "customerName": "Venugopal Xenia",
        "companyId": 20,
        "branchId": 152,
        "taxType": null,
        "weight": 3,
        "grossAmount": 45,
        "discountAmount": 0,
        "amount": 45,
        "taxableAmount": 0,
        "exempted": 45,
        "taxAmount": 0,
        "kfCessAmount": 0,
        "cessAmount": 0,
        "addCessAmount": 0,
        "netAmount": 45,
        "billDiscount": null,
        "shippingCharge": 0,
        "packingCharge": null,
        "deliveryCharge": 0,
        "otherExpense": null,
        "totalAmount": 45,
        "expectedDeliveryDate": null,
        "deliveryEmployee": null,
        "shippedDate": null,
        "deliveredDate": null,
        "receivedPersonName": null,
        "receivedPersonContact": null,
        "receivedPersonSignature": null,
        "deliveryRemarks": null,
        "status": "ORDERED",
        "lastUpdated": "2023-12-05T15:32:40.9502975+05:30",
        "latestRemarks": "Your Order has been placed",
        "orderAddresses": [
            {
                "orderAddressId": 4466,
                "orderId": 4508,
                "customerAddressId": 2120,
                "name": "Venugopal Xenia",
                "type": 1,
                "mobileNo1": "919946151007",
                "mobileNo2": "919946151007",
                "houseNo": "Xenia technologies",
                "area": "",
                "city": null,
                "state": "",
                "country": "",
                "landmark": "Federal",
                "pincode": "",
                "latitude": "9.9480929",
                "longitude": "76.3324508"
            }
        ],
        "orderItems": [
            {
                "orderItemsId": 24396,
                "orderId": 4508,
                "productId": 91054,
                "variantId": null,
                "name": "Frooti",
                "code": "Frooti",
                "variantName": null,
                "variantCode": null,
                "hsnCode": "",
                "attribute1": null,
                "option1": null,
                "attribute2": null,
                "option2": null,
                "attribute3": null,
                "option3": null,
                "attribute4": null,
                "option4": null,
                "sTaxIncl": false,
                "taxPer": 0,
                "mrp": 15,
                "quantity": 5,
                "unit": "-- None --",
                "rate": 15,
                "grossAmount": 15,
                "discount": 0,
                "amount": 15,
                "taxableAmount": 0,
                "exempted": 15,
                "taxAmount": 0,
                "vatPer": 0,
                "vatAmount": 0,
                "cgstPer": 0,
                "cgstAmount": 0,
                "sgstPer": 0,
                "sgstAmount": 0,
                "igstPer": 0,
                "igstAmount": 0,
                "cessPer": 0,
                "cessAmount": 0,
                "addCessPer": 0,
                "addCessAmt": 0,
                "kfCessper": 0,
                "kfCessamt": 0,
                "netAmount": 15,
                "itemWeight": 0,
                "returnEnabled": false,
                "returnDays": 0,
                "returnPolicy": null,
                "cartAddedDate": "2023-12-05T15:32:40.9815447+05:30",
                "expectedDeliveryDate": null,
                "actualDeliveryDate": null,
                "status": "ORDERED",
                "image": "https://admin.xeniacatalogue.info/Admin/ProductImage/20Saturday.11.November.202312.29.49",
                "isStockAvailable": null,
                "orderItemBundles": [],
                "isReturnable": false,
                "returnEligibleDate": null,
                "isReturned": null,
                "regionalLanguage": "",
                "incrementValue": 1,
                "comments": "",
                "discountedRate": 15,
                "savedAmount": null,
                "savedPercentage": null,
                "subTotal": 15
            },
            {
                "orderItemsId": 24397,
                "orderId": 4508,
                "productId": 91052,
                "variantId": null,
                "name": "Tea",
                "code": "Tea",
                "variantName": null,
                "variantCode": null,
                "hsnCode": "",
                "attribute1": null,
                "option1": null,
                "attribute2": null,
                "option2": null,
                "attribute3": null,
                "option3": null,
                "attribute4": null,
                "option4": null,
                "sTaxIncl": false,
                "taxPer": 0,
                "mrp": 0,
                "quantity": 1,
                "unit": "-- None --",
                "rate": 10,
                "grossAmount": 10,
                "discount": 0,
                "amount": 10,
                "taxableAmount": 0,
                "exempted": 10,
                "taxAmount": 0,
                "vatPer": 0,
                "vatAmount": 0,
                "cgstPer": 0,
                "cgstAmount": 0,
                "sgstPer": 0,
                "sgstAmount": 0,
                "igstPer": 0,
                "igstAmount": 0,
                "cessPer": 0,
                "cessAmount": 0,
                "addCessPer": 0,
                "addCessAmt": 0,
                "kfCessper": 0,
                "kfCessamt": 0,
                "netAmount": 10,
                "itemWeight": 0,
                "returnEnabled": false,
                "returnDays": 0,
                "returnPolicy": null,
                "cartAddedDate": "2023-12-05T15:32:40.9971743+05:30",
                "expectedDeliveryDate": null,
                "actualDeliveryDate": null,
                "status": "ORDERED",
                "image": "https://admin.xeniacatalogue.info/Admin/ProductImage/20Saturday.11.November.202309.31.03milk-tea-recipe.jpg",
                "isStockAvailable": null,
                "orderItemBundles": [],
                "isReturnable": false,
                "returnEligibleDate": null,
                "isReturned": null,
                "regionalLanguage": "",
                "incrementValue": 1,
                "comments": "",
                "discountedRate": 10,
                "savedAmount": null,
                "savedPercentage": null,
                "subTotal": 10
            },
            {
                "orderItemsId": 24398,
                "orderId": 4508,
                "productId": 91053,
                "variantId": null,
                "name": "Coffee",
                "code": "Coffee",
                "variantName": null,
                "variantCode": null,
                "hsnCode": "",
                "attribute1": null,
                "option1": null,
                "attribute2": null,
                "option2": null,
                "attribute3": null,
                "option3": null,
                "attribute4": null,
                "option4": null,
                "sTaxIncl": false,
                "taxPer": 0,
                "mrp": 0,
                "quantity": 1,
                "unit": "-- None --",
                "rate": 20,
                "grossAmount": 20,
                "discount": 0,
                "amount": 20,
                "taxableAmount": 0,
                "exempted": 20,
                "taxAmount": 0,
                "vatPer": 0,
                "vatAmount": 0,
                "cgstPer": 0,
                "cgstAmount": 0,
                "sgstPer": 0,
                "sgstAmount": 0,
                "igstPer": 0,
                "igstAmount": 0,
                "cessPer": 0,
                "cessAmount": 0,
                "addCessPer": 0,
                "addCessAmt": 0,
                "kfCessper": 0,
                "kfCessamt": 0,
                "netAmount": 20,
                "itemWeight": 0,
                "returnEnabled": false,
                "returnDays": 0,
                "returnPolicy": null,
                "cartAddedDate": "2023-12-05T15:32:40.9971743+05:30",
                "expectedDeliveryDate": null,
                "actualDeliveryDate": null,
                "status": "ORDERED",
                "image": "https://admin.xeniacatalogue.info/Admin/ProductImage/20Saturday.11.November.202309.31.30coffee_its_benefits_898_1_.jpg",
                "isStockAvailable": null,
                "orderItemBundles": [],
                "isReturnable": false,
                "returnEligibleDate": null,
                "isReturned": null,
                "regionalLanguage": "",
                "incrementValue": 1,
                "comments": "",
                "discountedRate": 20,
                "savedAmount": null,
                "savedPercentage": null,
                "subTotal": 20
            }
        ],
        "orderPayments": [
            {
                "orderPaymentId": 3328,
                "orderId": 4508,
                "paymentProviderType": "MANUAL",
                "paymentProviderId": null,
                "paymentProviderName": null,
                "paymentMethod": null,
                "paymentDate": "2023-12-05T10:02:40.741Z",
                "paymentRef": null,
                "paymentAmount": 45,
                "paymentMessage": null,
                "status": null
            }
        ],
        "orderStatusTracks": [
            {
                "orderDate": "2023-12-05T15:32:40.9971743+05:30",
                "status": "ORDERED",
                "remarks": "Your Order has been placed"
            }
        ],
        "orderDiscounts": [],
        "isCancellable": false,
        "isCancelled": null,
        "cancelReasonId": null,
        "cancelReasonText": null,
        "cancelRemarks": null,
        "timeSlotId": 0,
        "deliveryType": 19,
        "isDeliveryAvailable": false,
        "netTaxAmount": 0,
        "roundOff": 0
    }
    const orderDate = new Date("2023-12-05T10:06:17.3213225+05:30").toLocaleDateString()

    const generateQrImage = () => {
        const myDiv = document.getElementById('qrDiv');
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (myDiv) {
            html2canvas(myDiv).then((canvasData) => {
                const img = new Image();
                img.src = canvasData.toDataURL('image/png');
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);

                    // Convert canvas content to a data URL
                    const pngFile = canvas.toDataURL('image/png');

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.download = 'OrderReceipt.png';
                    downloadLink.href = pngFile;

                    // Trigger a click event on the download link to prompt the user to download the image
                    downloadLink.click();
                };
            });
        } else {
            console.error("Div element with ID 'myDiv' not found");
        }
    };
    return (
        <div style={{ backgroundColor: 'white', padding: 30, display: 'block', alignItems: 'center', flexDirection: 'column', borderRadius: 3, justifyContent: 'center', width: '50%', height: '80%', overflow: 'auto', }} >
            <span style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <img src={VerifiedIcon} />
            </span>
            <h1 style={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontWeight: 'bold' }}>Your order was placed successfully</h1>
            <div id="qrDiv" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 30, paddingBlock: 15, width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: 15 }}>
                    <QRCode
                        size={128}
                        value={String(orderPlacedData?.orderNo)}
                        style={{ height: 'auto' }}
                    />
                </div>
                <div style={{ borderTop: 'dashed', marginTop: 30, paddingBlock: 15, borderTopWidth: 1 }}>
                    <span >
                        <h5>Order details</h5>
                        <div style={{ marginInline: 15 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Order No :</div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.orderNo}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Order Date : </div>
                                <div style={{ fontWeight: '500', }}>{orderDate}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Customer name :</div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.customerName}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: '500', flex: 1 }}>Mobile number :</div>
                                <div style={{ fontWeight: '500', }}>{orderPlacedData?.orderAddresses[0].mobileNo1}</div>
                            </div>
                        </div>
                    </span>
                    <div style={{ marginBlock: 15, display: 'flex', flex: 1 }}>
                        <table style={{ width: '100%' }}>
                            <thead style={{ borderBlock: 'dashed', borderBlockWidth: 1 }}>
                                <tr >
                                    <th style={{ textAlign: 'center', paddingBlock: 8 }}>Items</th>
                                    <th style={{ textAlign: 'center' , padding: 8}}>Quantity</th>
                                    <th style={{ textAlign: 'center', padding: 8 }}>Total</th>
                                </tr>
                            </thead>
                            <tbody style={{ paddingBlock: 15 }}>
                                {orderPlacedData?.orderItems.map((item, index) =>
                                    <tr style={{ }} key={index} >
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ flex: 1, marginRight: 10 }}>{item.name}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div>x{item.quantity} </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div>{currencySymbol} {item.subTotal}</div>
                                        </td>
                                    </tr>
                                )}
                                <tr style={{ borderTop: 'dashed', borderTopWidth: 1, borderSpacing:`0 1rem` }}>
                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{ fontWeight: 'bold'}}>Subtotal</div>
                                    </td>
                                    <td></td>
                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{ fontWeight: 'bold', }}>{currencySymbol} {orderPlacedData.amount}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>


            </div>
            <div style={{ fontWeight: '500', color: '#00000075', fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                Thank you so much for your order
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="download-button" onClick={generateQrImage}>
                    <img src={DownloadIcon} style={{ marginRight: 15 }} />
                    Download Qr
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 15, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer', minWidth: '20%', }} onClick={() => navigate('/')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.223" height="21.225" viewBox="0 0 19.223 21.225" stroke='#fff'>
                        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.9 -2.4)">
                            <path id="Path_114" data-name="Path 114" d="M4.5,10.009,13.511,3l9.011,7.009V21.023a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                            <path id="Path_115" data-name="Path 115" d="M13.5,28.013V18h6.008V28.013" transform="translate(-2.992 -4.987)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                        </g>
                    </svg>
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Home</p>
                </div>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer', minWidth: '20%' }} onClick={() => navigate('/Categories')}>
                    <img src={TabCategory} />
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Category</p>
                </div>
            </div>
        </div>
    )

}